import { Box } from "ds/Box";
import { Icon } from "ds/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

import {
  FormItemContainer,
  FormItemContent,
  FormItemHint,
  FormItemLabel,
  FormItemLabelContainer,
  FormItemLabelTag,
  FormItemStatusMessage,
  FormLabel,
} from "./styles";
import { FormItemProps } from "./types";

const FormItem = ({
  children,
  hint,
  label,
  labelHint,
  id = uuid(),
  optional,
  status,
  tooltip,
  labelExtras,
  ...rest
}: FormItemProps) => {
  const { t } = useTranslation();

  return (
    <FormItemContainer {...rest}>
      {label && (
        <FormLabel htmlFor={id}>
          <FormItemLabelContainer>
            <Box flexDirection="row" alignItems="center" gap="100">
              <FormItemLabel>{label}</FormItemLabel>
              {tooltip && <Icon name="HelpCircleIcon" />}
              {optional && <FormItemLabelTag>{t("form.labels.optional")}</FormItemLabelTag>}
            </Box>
            {labelExtras}
          </FormItemLabelContainer>
          {labelHint && <FormItemHint>{labelHint}</FormItemHint>}
        </FormLabel>
      )}
      <FormItemContent withStatus={!!status}>{React.cloneElement(children, { id })}</FormItemContent>
      {status && <FormItemStatusMessage status={status?.type}>{status.message}</FormItemStatusMessage>}
      {hint && <FormItemHint>{hint}</FormItemHint>}
    </FormItemContainer>
  );
};
FormItem.displayName = "FormItem";

export { FormItem };

// todo(alexandrchebotar, 2022-11-10):
// 1. Types
// 2. Docs
