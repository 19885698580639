import { Clean } from "api/boundary";
import { RoleType } from "graphql/generated/types";
import i18n from "utils/i18n";

import { ACCOUNT_DETAILS_FRAGMENT, ORGANISATION_ACCOUNT_DETAILS_FRAGMENT } from "./fragments";
import { AccountDetailsFragment, OrganisationAccountDetailsFragment } from "./generated/fragments.generated";

export type TAccount = Clean<typeof ACCOUNT_DETAILS_FRAGMENT.parse, AccountDetailsFragment>;
export type TOrganisationAccount = Clean<
  typeof ORGANISATION_ACCOUNT_DETAILS_FRAGMENT.parse,
  OrganisationAccountDetailsFragment
>;

export const DisplayRoles: Record<RoleType, string> = {
  ADMIN: i18n.t("organization-members.role-cards.admin.title"),
  BUILDER: i18n.t("organization-members.role-cards.builder.title"),
  OPERATOR: i18n.t("organization-members.role-cards.operator.title"),
};
