import * as Sentry from "@sentry/react";
import { Socket as PhoenixSocket } from "phoenix";
import { useEffect, useRef } from "react";

interface IUseSocketRetries {
  socket?: PhoenixSocket;
  onSocketRetry: () => Promise<void>;
  onReconnectionFailed: () => void;
  setConnectionLost: (arg: boolean) => void;
  intervals?: number[];
}
export const useSocketRetries = ({
  socket,
  onSocketRetry,
  onReconnectionFailed,
  setConnectionLost,
  intervals = [0, 300, 1000, 3000, 10000],
}: IUseSocketRetries) => {
  const reconnectionTries = useRef(0);

  useEffect(() => {
    socket?.onOpen(() => {
      reconnectionTries.current = 0;
    });

    socket?.onError(() => {
      setConnectionLost(true);

      socket.disconnect(() => {
        const tries = reconnectionTries.current;

        if (tries < 5) {
          setTimeout(() => {
            onSocketRetry().then(() => {
              reconnectionTries.current = tries + 1;
              socket.connect();
            });
          }, intervals[tries]);
        } else {
          onReconnectionFailed();
          if (window.location.pathname.endsWith("/app/522")) return;
          Sentry.captureException(new Error("Failed to reconnect WebSocket for 5 times"));
        }
      });
    });
    // context(alexandrchebotar, 27 July 2021): ignore intervals dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, onSocketRetry, onReconnectionFailed, setConnectionLost]);
};
