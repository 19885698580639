import { AbsintheSocket, create as createAbsintheSocket } from "@absinthe/socket";
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link";
import { ApolloClient, ApolloLink, InMemoryCache, split } from "@apollo/client";
import { browserTracingIntegration, captureException, init } from "@sentry/react";
import { SamplingContext } from "@sentry/types";
import { SentryLink } from "apollo-link-sentry";
import DSThemeProvider from "ds/theme";
import { Grommet } from "grommet";
import { Socket as PhoenixSocket } from "phoenix";
// import posthog from "posthog-js";
// import { PostHogProvider } from "posthog-js/react";
import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { render } from "react-dom";
import { I18nextProvider } from "react-i18next";
import { ThemeProvider } from "styled-components";

import { DEFAULT_APP_CONFIG } from "_constants";
import { possibleTypes, typePolicies } from "api/schemaMetadata";
import App, { networkStatusLink } from "components/App";
import { usePhxErrorHandler } from "hooks/usePhxErrorHandler";
import { useSocketRetries } from "hooks/useSocketRetries";
import { theme } from "lib/impact";
import { ConnectingLoader } from "lib/loaders/FullPageLoader";
import { DisconnectBanner } from "lib/notification/DisconnectBanner";
import { Checkout } from "pages/Checkout/Checkout";
import { Login } from "pages/Login";
import { MFASignIn } from "pages/Login/components";
import { Profitwell } from "pages/Profitwell";
import { turnTheme } from "theme";
import { TApolloClient as TClient } from "types";
import { getAccount } from "utils/API";
import { LoggerLink, createHttpLink, shouldUseAbsintheSocketLink } from "utils/apollo";
import { insertIf } from "utils/array";
import { WEBSOCKET_REQUEST_TIMEOUT } from "utils/constants";
import { GlobalProvider } from "utils/context";
import { ModalProvider } from "utils/context/modal";
import { PaymentProvider } from "utils/context/payment";
import { SelectionProvider } from "utils/context/selection";
import i18next from "utils/i18n/index";
import { preloadAppRouteChunks } from "utils/lazy";

// import "@backlight-dev/turnio.design-system/style.css";
// import "ds/style.css";
import "@uppy/core/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import "animate.css";
import "react-confirm-alert/src/react-confirm-alert.css";

import "./index.css";

require("./polyfills");

const APP_CONFIG = {
  ...DEFAULT_APP_CONFIG,
  ...(window.APP_CONFIG || {}),
};

// if (process.env.NODE_ENV === "production" && APP_CONFIG.posthog_key) {
//   posthog.init(APP_CONFIG.posthog_key, {
//     persistence: "localStorage+cookie",
//     api_host: "https://app.posthog.com",
//     session_recording: {
//       maskAllInputs: true,
//     },
//   });
// }
if (process.env.NODE_ENV !== "development" && APP_CONFIG.sentry_dsn) {
  const sentryConfig = {
    dsn: APP_CONFIG.sentry_dsn,
    environment: `frontend-${process.env.NODE_ENV || "production"}`,
    release: "turnio@" + APP_CONFIG.turn_release,
    integrations: [browserTracingIntegration()],
    tracesSampler: ({ location }: SamplingContext) => {
      const urlParams = new URLSearchParams(location?.search);
      const getTrace = urlParams.get("trace") as string;
      const trace = parseFloat(getTrace);
      return trace >= 1 ? 1 : trace > 0 ? trace : 0.1;
    },
  };
  init(sentryConfig);
}

interface IApolloClientBootstrapProps {
  children: (props: {
    authenticated: boolean;
    mfaEnabled: boolean;
    setMfaCode: (string) => void;
    client?: TClient;
    loading: boolean;
    connectionLost: boolean;
    reconnectionFailed: boolean;
    logOut: () => void;
  }) => JSX.Element;
}

const ApolloClientBootstrap = ({ children }: IApolloClientBootstrapProps) => {
  const [client, setClient] = useState<TClient>();
  const [authenticated, setAuthenticated] = useState(true);
  const [mfaCode, setMfaCode] = useState("");
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [connectionLost, setConnectionLost] = useState(false);
  const [reconnectionFailed, onReconnectionFailed] = useReducer(() => true, false);
  const _socket = useRef<PhoenixSocket>();
  const _absintheSocket = useRef<AbsintheSocket>();
  const token = useRef<string>();

  useEffect(() => {
    if (client) {
      if (process.env.NODE_ENV === "development" || process.env.REACT_APP_TEST === "1") {
        window.__turnDebug.apolloClient = client;
      }
    }
  }, [client]);

  const onSocketRetry = useCallback(
    () =>
      getAccount({ mfa: mfaCode })
        .then((account) => {
          setMfaEnabled(account.mfa_enabled);
          setAuthenticated(true);
          token.current = account.jwt;
        })
        .catch(() => {
          setAuthenticated(false);
        }),
    [mfaCode]
  );

  useSocketRetries({
    socket: _socket.current,
    onSocketRetry,
    onReconnectionFailed,
    setConnectionLost,
  });

  usePhxErrorHandler({
    socket: _socket.current,
    absintheSocket: _absintheSocket.current,
  });

  const onSocket = useCallback(
    () =>
      getAccount({ mfa: mfaCode })
        .then((account) => {
          if (account.mfa_enabled && !account.jwt) {
            setAuthenticated(true);
            setMfaEnabled(true);
            return;
          } else {
            token.current = account.jwt;
            return connectToWebSocket();
          }
        })
        .catch(() => {
          setAuthenticated(false);
        })
        .finally(() => {
          setLoading(false);
        }),
    [mfaCode]
  );

  const connectToWebSocket = () =>
    Promise.resolve(
      new PhoenixSocket(`${APP_CONFIG.turn_ws_host}/socket`, {
        timeout: WEBSOCKET_REQUEST_TIMEOUT,
        params: () => ({
          token: token.current,
        }),
      })
    )
      .then((socket) => {
        socket.onOpen(() => {
          setConnectionLost(false);
        });
        socket.onClose(() => {
          setConnectionLost(true);
        });

        const absintheSocket = createAbsintheSocket(socket);

        _socket.current = socket;
        _absintheSocket.current = absintheSocket;

        return {
          httpLink: createHttpLink({
            uri: `${APP_CONFIG.turn_host}/graphql`,
            onAuthenticationError: () => setAuthenticated(false),
          }),
          absintheSocketLink: createAbsintheSocketLink(
            absintheSocket,
            ...insertIf(process.env.NODE_ENV === "development", onDevSocketError)
          ),
        };
      })
      .then(
        ({ httpLink, absintheSocketLink }) =>
          new ApolloClient({
            link: ApolloLink.from([
              networkStatusLink,
              new SentryLink({
                attachBreadcrumbs: {
                  includeVariables: true,
                  includeError: true,
                },
              }),
              ...insertIf(
                process.env.REACT_APP_TEST === "1" || process.env.NODE_ENV === "development",
                new LoggerLink()
              ),
              split(shouldUseAbsintheSocketLink, absintheSocketLink, httpLink),
            ]),
            cache: new InMemoryCache({
              possibleTypes,
              typePolicies,
            }),
            defaultOptions: {
              watchQuery: {
                nextFetchPolicy(lastFetchPolicy, info) {
                  if (info.reason === "variables-changed") {
                    return info.initialFetchPolicy;
                  }
                  if (lastFetchPolicy === "cache-and-network") {
                    return "cache-first";
                  }
                  return lastFetchPolicy;
                },
              },
            },
          })
      )
      .then((client) => {
        setClient(client);
      });

  useEffect(() => {
    preloadAppRouteChunks();
  }, []);

  useEffect(() => {
    onSocket();
  }, [onSocket]);

  const logOut = () => {
    _socket.current?.disconnect();
    fetch(`${APP_CONFIG.turn_host}/account/logout`, {
      credentials: "include",
      redirect: "manual",
    }).then((response) => {
      if (response.type === "opaqueredirect") {
        // context(2022-04-15, alexandrchebotar): push root path to prevent redirection to forbidden page
        window.history.pushState({}, "", "/");
        setAuthenticated(false);
      }
    });
  };

  return children({
    client,
    authenticated,
    mfaEnabled,
    setMfaCode,
    loading,
    connectionLost,
    reconnectionFailed,
    logOut,
  });
};

render(
  <I18nextProvider i18n={i18next}>
    <Grommet theme={turnTheme}>
      {window.location.pathname.endsWith("/checkout") ? (
        <Checkout />
      ) : window.location.pathname.endsWith("/profitwell") ? (
        <Profitwell />
      ) : (
        <ApolloClientBootstrap>
          {({ client, authenticated, setMfaCode, mfaEnabled, loading, connectionLost, reconnectionFailed, logOut }) => (
            <>
              <ThemeProvider theme={theme}>
                <DSThemeProvider>
                  {loading && <ConnectingLoader />}
                  {!authenticated ? (
                    <Login turnHostInfo={APP_CONFIG} />
                  ) : mfaEnabled && !client ? (
                    <MFASignIn setMfaCode={setMfaCode} />
                  ) : (
                    <>
                      {connectionLost && <DisconnectBanner reconnectionFailed={reconnectionFailed} logOut={logOut} />}
                      {client && !loading && (
                        // <PostHogProvider>
                        <GlobalProvider turnHostInfo={APP_CONFIG} logOut={logOut}>
                          <ModalProvider>
                            <PaymentProvider>
                              <SelectionProvider>
                                <App client={client} />
                              </SelectionProvider>
                            </PaymentProvider>
                          </ModalProvider>
                        </GlobalProvider>
                        // </PostHogProvider>
                      )}
                    </>
                  )}
                </DSThemeProvider>
              </ThemeProvider>
            </>
          )}
        </ApolloClientBootstrap>
      )}
    </Grommet>
  </I18nextProvider>,
  document.getElementById("root")
);

window.__turnDebug = {
  // context(justinvdm, 7 November 2021): We cast to TClient to simplify use of window.__turnDebug:
  // the alternatives are declaring the type as nullable (which makes the type awkward to work with),
  // creating a dummy client here (overkill), or some wrapper API that deals with the nulls implictly (overkill)
  apolloClient: null as unknown as TClient,
  testSentry() {
    captureException(new Error("Test error, please ignore"));
  },
};

// context(justinvdm, 7 November 2021): We need to rewrite the error message, otherwise
// the error message shown to us is a not-so-helpful "Error"
function onDevSocketError(error: Error) {
  error.message = `Received socket error from absinthe: ${error.toString()}`;
  Promise.reject(error);
}
