import gql from "graphql-tag";

import { boundary, required } from "api/boundary";

import { NUMBER_MIN_FRAGMENT } from "../number/fragments";

export const SETTINGS_NUMBER_FRAGMENT = {
  query: gql`
    fragment SettingsNumber on Number {
      ...NumberMinFragment
      aboutUpdatedAt
      generalRateLimit
      textRateLimit
      mediaRateLimit
      about
      description
      email
      address
      vertical
      websites
      numberRoute {
        uuid
        fromAddr
      }
      streamStates {
        uuid
        table
        lastSync
      }
      hasBigqueryServiceAccount
      bigqueryErrors
      notificationsEmail
      isRoutingEnabled
      operatorCapacityForRouting
      contactSchema {
        uuid
      }
    }

    ${NUMBER_MIN_FRAGMENT.query}
  `,
  parse: {
    ...NUMBER_MIN_FRAGMENT.parse,
    numberRoute: boundary({
      fromAddr: required(),
    }),
    contactSchema: {
      uuid: required(),
    },
  },
};
