import { Select } from "ds/Select";
import { SelectProps } from "ds/Select/src/types";
import React from "react";
import { useTranslation } from "react-i18next";

export const Sort = ({ sort }: { sort?: SelectProps<string, { label: string; value: string }> }) => {
  const { t } = useTranslation();

  return sort ? <Select size="small" placeholder={t("form.labels.order-by")} {...sort} /> : null;
};
